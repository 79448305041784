:root {
  --sidebar-bg-color: #DAE0E2;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.navbar-custom {
  background: rgb(6, 6, 6);
  background: linear-gradient( 90deg, rgba(6, 6, 6, 1) 0%, rgba(235, 0, 0, 1) 81%);
}

.footer-custom {
  background: rgb(255, 0, 0);
  background: linear-gradient( 90deg, rgba(255, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 100%);
  margin-top: 10px;
  height: 25px;
}

.footer-custom .navbar-brand {
    font-size: .75rem !important;
}

.input-error {
  color: rgb(255, 0, 0);
}

.ponter-on-hover:hover {
  cursor: pointer;
}

.bootstrap-striped-table2 tbody tr:nth-of-type(odd) {
    background-color: #9E9E9E;
}

.font10 {
  font-size: 10px;
}

.breadcrumb-item.active {
    font-weight: bold;
}