#divLoading {
    display: block;
    position: fixed;
    z-index: 1021;
    background-image: url('http://loadinggif.com/images/image-selection/3.gif');
    background-color: #666;
    opacity: 0.4;
    background-repeat: no-repeat;
    background-position: center;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
}